
import './jquery';
import 'popper.js';
import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css';

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

import '../css/style.css';
import '../css/responsive.css';


const swiper = new Swiper(".mySwiper", {
    loop: false,
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
});

new Swiper(".mySwiper2", {
    loop: false,
    spaceBetween: 10,
     
    thumbs: {
        swiper: swiper,
    },
});

$(function () {
    $('.services-box').click(function () {

      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $('#service_description').hide();
        $('#service_description').html('');
        return;
      }
      $('.services-box').removeClass('active');
      $(this).addClass('active');
      var content = $(this).find('.service-box-text').html();
      $('#service_description').html(content);
      $('#service_description').show();

      if ((window.innerHeight + window.scrollY) < ($('#service_description').offset().top + 50)) {
        $(window).scrollTop($('#service_description').offset().top - 200);
      }
    });

    // $(window).on('activate.bs.scrollspy', function (e, o) {
    //   let path = o.relatedTarget.replace(/#/, '');
    //   console.log("This event is not firing...");
    // });
  });